export enum ROLES {
	ADMIN = 'ADMIN',
	MENTOR = 'MENTOR',
	INVESTOR = 'INVESTOR',
	INNOVATOR = 'INNOVATOR'
}

export type ROLE = 'ADMIN' | 'MENTOR' | 'INNOVATOR' | 'INVESTOR'

export const RolePermissions = {
	'project.read': ['ADMIN', 'MENTOR', 'INNOVATOR'],
	'project.update': ['ADMIN', 'MENTOR'],
	'project.delete': ['ADMIN'],
	'project.add_member': ['ADMIN', 'MENTOR'],
	'project.update_member_role': ['ADMIN', 'MENTOR'],
	'project.remove_member:any': ['ADMIN', 'MENTOR'],
	'project.remove_member:own': ['MENTOR', 'INNOVATOR'],
	'project.answer_nda': ['ADMIN', 'MENTOR', 'INNOVATOR'],
	'project.read_world_cloud': ['ADMIN', 'MENTOR', 'INNOVATOR'],
	'project.add_connection_link': ['ADMIN', 'MENTOR'],
	'project.remove_connection_link': ['ADMIN', 'MENTOR'],
	'project.invite_users': ['ADMIN', 'MENTOR'],
	'map.create': ['ADMIN', 'MENTOR'],
	'map.read': ['ADMIN', 'MENTOR', 'INNOVATOR'],
	'map.update': ['ADMIN', 'MENTOR'],
	'map.delete': ['ADMIN', 'MENTOR'],
	'content.create': ['ADMIN', 'MENTOR'],
	'content.read': ['ADMIN', 'MENTOR', 'INNOVATOR'],
	'content.list': ['ADMIN', 'MENTOR', 'INNOVATOR'],
	'content.delete': ['ADMIN', 'MENTOR'],
	'content.attach_link': ['ADMIN', 'MENTOR', 'INNOVATOR'],
	'question_comment.create': ['ADMIN', 'MENTOR', 'INNOVATOR'],
	'question_comment.list': ['ADMIN', 'MENTOR', 'INNOVATOR'],
	'question_comment.add_agreement': ['ADMIN', 'MENTOR', 'INNOVATOR'],
	'question_comment.remove_agreement': ['ADMIN', 'MENTOR', 'INNOVATOR'],
	'question_comment.delete:own': ['ADMIN', 'MENTOR', 'INNOVATOR'],
	'checkpoint.create': ['ADMIN', 'MENTOR'],
	'checkpoint.read': ['ADMIN', 'MENTOR', 'INNOVATOR'],
	'checkpoint.update': ['ADMIN', 'MENTOR'],
	'checkpoint.delete': ['ADMIN', 'MENTOR'],
	'checkpoint.close': ['ADMIN', 'MENTOR'],
	'checkpoint_comment.create': ['ADMIN', 'MENTOR'],
	'checkpoint_comment.list': ['ADMIN', 'MENTOR', 'INNOVATOR'],
	'checkpoint_comment.delete:own': ['ADMIN', 'MENTOR', 'INNOVATOR'],
	'convergence_point.create': ['ADMIN', 'MENTOR'],
	'convergence_point.read': ['ADMIN', 'MENTOR', 'INNOVATOR'],
	'convergence_point.update': ['ADMIN', 'MENTOR'],
	'convergence_point.answer': ['ADMIN', 'MENTOR', 'INNOVATOR'],
	'convergence_point.delete': ['ADMIN', 'MENTOR'],
	'divergence.delete': ['ADMIN', 'MENTOR'],
	'lab.update_logo:own': ['ADMIN'],
	'point.add': ['ADMIN', 'MENTOR'],
	'useful_link.create': ['ADMIN', 'MENTOR'],
	'useful_link.delete': ['ADMIN', 'MENTOR'],
	'journey.abandon': ['ADMIN'],
	'journey.delete': ['ADMIN'],
	'journey.mentor': ['MENTOR'],
	'journey.request_nda': ['ADMIN'],
	'journey.analysis_qualitative': ['ADMIN', 'MENTOR'],
	'notice_point.options': ['ADMIN', 'MENTOR'],
	'anonym_mode.toggle': ['ADMIN', 'MENTOR'],
	'point.comment_all': ['ADMIN', 'MENTOR'],
	'journey.member': ['INNOVATOR'],
	'evaluation_point.evaluate': ['ADMIN', 'MENTOR']
} as const

type Permission = keyof typeof RolePermissions

export function Role(roles: ROLE[] = [], user?: { id: string }) {
	return {
		can(permission: Permission) {
			const acceptableRoles = RolePermissions[permission] as readonly ROLE[]
			return roles.some((role) => acceptableRoles.includes(role))
		},
		isOwner({ id }: { id: string }) {
			if (!user) return false
			return id === user.id
		}
	}
}
