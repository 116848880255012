import { useContext } from 'react'
import { useController, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { SignUpContext } from '..'

import {
	VStack,
	Checkbox,
	Text,
	FormControl,
	FormErrorMessage,
	Button,
	useDisclosure,
	HStack,
	Box
} from '@chakra-ui/react'

import ModalTermsAndConditions from 'modules/common/components/terms-of-use'

export const AcceptTermsField = () => {
	const { t } = useTranslation()
	const disclosure = useDisclosure()

	const { termsChecked, setTermsChecked } = useContext(SignUpContext)

	const { control, setValue } = useFormContext()
	const {
		field,
		fieldState: { error }
	} = useController({
		control,
		name: 'term_accepted'
	})

	const handleChange = (name: string, value: string) => {
		setTermsChecked(!termsChecked)
		setValue(name, value)
	}

	return (
		<VStack spacing={4} mt={8} alignItems='flex-start'>
			<FormControl isInvalid={!!error}>
				<Checkbox
					isChecked={termsChecked}
					onChange={(e) => {
						handleChange('term_accepted', e.target.value)
						field.onChange(e)
					}}
				>
					<HStack>
						<Text fontSize={['xs', 'md']} fontWeight='medium' color='gray.400'>
							{t('signup:acceptTerms')}
						</Text>
						<Button
							variant='link'
							colorScheme='blue'
							fontSize={['xs', 'md']}
							onClick={disclosure.onOpen}
						>
							{t('buttons:termsOfUse')}
						</Button>
					</HStack>
				</Checkbox>
				{error && <FormErrorMessage>{error?.message}</FormErrorMessage>}
			</FormControl>
			<ModalTermsAndConditions
				{...disclosure}
				renderHeader={() => (
					<Box>
						<Text mr={2} fontSize={['lg', '2xl']}>
							{t('signup:termsAndConditions')}
						</Text>
					</Box>
				)}
				renderFooter={<Box></Box>}
			></ModalTermsAndConditions>
		</VStack>
	)
}
