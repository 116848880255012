import { useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex, HStack, Icon, Text, VStack } from '@chakra-ui/react'

import { IconStStrateegiaBlue } from 'modules/common/components/icons'
import { handleDateFormat } from 'modules/common/utils/change-date-format'
import { EvaluationResponse } from 'modules/map/types'

type Props = {
	content: EvaluationResponse
	userLanguage: string
}

export const EvaluationCard = ({ content, userLanguage }: Props) => {
	const { t } = useTranslation()
	const boxCommentRef = useRef<HTMLDivElement | null>(null)
	const baseGrade = Math.round(content.evaluation_result.base_grade)

	return (
		<Flex
			height='fit-content'
			w='full'
			mt='2'
			px={5}
			py={5}
			flexDirection='column'
			borderRadius='8'
			border='1px solid'
			borderColor='blue.500'
			backgroundColor='#fff'
			ref={boxCommentRef}
		>
			<HStack justify='space-between'>
				<HStack>
					<Icon w={10} h={10} as={IconStStrateegiaBlue} />
					<Text fontWeight='semibold' fontSize='sm'>
						{t('evaluationPoint:essay.evaluation.title')}
					</Text>
				</HStack>
				<Text color='#8B90A0' fontWeight='500' fontSize='xs' mr='2'>
					{handleDateFormat(
						content.created_at,
						userLanguage === 'PT_BR' ? 'ptBR' : ''
					)}
				</Text>
			</HStack>
			<VStack mt={4}>
				<Text variant='breakLines'>{content.evaluation_result.result}</Text>
				<VStack>
					<Text fontWeight='semibold' fontSize='sm'>
						{t('evaluationPoint:essay.evaluation.finalGrade')}
					</Text>
					<Text mt={0} fontWeight='bold' fontSize='3xl'>
						{baseGrade}
					</Text>
				</VStack>
			</VStack>
		</Flex>
	)
}
