import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { Box, SkeletonText } from '@chakra-ui/react'

import './styles.css'

const fetchTerms = (lang: string = 'pt') => {
	const slug = lang === 'pt' ? 'termos-e-condicoes' : 'terms-and-conditions'

	const url = `https://strateegia.digital/wp-json/wp/v2/pages?slug=${slug}&lang=${lang}`
	return fetch(url, {
		headers: {
			'Content-Type': 'application/json; charset=UTF-8'
		}
	})
		.then((res) => res.json())
		.then(([page]) => page.content.rendered)
}

function UserTermsText() {
	const { i18n } = useTranslation()
	const query = useQuery(
		['term', i18n.language],
		() => fetchTerms(i18n.language),
		{
			staleTime: Infinity
		}
	)

	if (query.isLoading) {
		return <SkeletonText noOfLines={12} />
	}

	if (query.isSuccess) {
		return (
			<Box className='term-wrap'>
				<div dangerouslySetInnerHTML={{ __html: query.data }}></div>
			</Box>
		)
	}

	return null
}

export default UserTermsText
